<template>
  <div class="authentication">
    <b-container class="login-card-container">
      <b-card class="auth-card">
        <form name="form" @submit.prevent="handleAuthentication">
          <div v-if="!successful">
            <!-- Email -->
            <div class="form-group">
              <label for="email">{{ $t("email") }}</label>
              <input
                v-model="user.email"
                v-validate="'required|min:3|max:50'"
                type="text"
                class="form-control"
                name="email"
              />
              <div v-if="submitted && errors.has('email')" class="alert-danger">
                {{ errors.first("email") }}
              </div>
            </div>
            <!-- Firstname -->
            <div class="form-group" v-if="authenticationType == 'basic'">
              <label for="firstname">{{ $t("firstname") }}</label>
              <input
                v-model="user.firstname"
                v-validate="'required|max:50'"
                type="text"
                class="form-control"
                name="firstname"
              />
              <div
                v-if="submitted && errors.has('firstname')"
                class="alert-danger"
              >
                {{ errors.first("firstname") }}
              </div>
            </div>
            <!-- Lastname -->
            <div class="form-group" v-if="authenticationType == 'basic'">
              <label for="lastname">{{ $t("lastname") }}</label>
              <input
                v-model="user.lastname"
                v-validate="'required|max:40'"
                type="text"
                class="form-control"
                name="lastname"
              />
              <div
                v-if="submitted && errors.has('lastname')"
                class="alert-danger"
              >
                {{ errors.first("lastname") }}
              </div>
            </div>

            <!-- Code -->
            <div class="form-group" v-if="authenticationType == 'code'">
              <label for="code">{{ $t("code") }}</label>
              <input
                v-model="user.code"
                v-validate="'required|max:50'"
                type="text"
                class="form-control"
                name="code"
              />
              <div v-if="errors.has('code')" class="alert-danger">
                {{ errors.first("code") }}
              </div>
            </div>
            <!-- GDPR -->
            <div>
              <div>
                <input
                  type="checkbox"
                  v-model="accepted"
                  v-validate="'required'"
                  name="terms"
                  value="accepted"
                />
                {{ $t("acceptPolicy")
                }}<router-link
                  target="_blank"
                  :to="`/cookie-privacy-${getLangConcat}`"
                  ><a class="link-privacy">{{
                    $t("privacyPolicy")
                  }}</a></router-link
                >.<br />
              </div>
              <div v-if="errors.has('terms')" class="alert-danger">
                {{ errors.first("terms") }}
              </div>
            </div>
            <!-- Submit  -->
            <div class="submit-button-container form-group">
              <b-button type="submit" block>{{ $t("signup") }}</b-button>
            </div>
          </div>
        </form>

        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";

export default {
  name: "Authentication",
  data() {
    return {
      user: { email: "", firstname: "", lastname: "", code: "" },
      submitted: false,
      successful: false,
      accepted: false,
      message: "",
    };
  },
  watch: {
    // Checks if user is logged in with basic Auth, push to home when true
    isLoggedIn: function() {
      if (this.isLoggedIn) {
        this.$router.push("/");
      }
    },
  },
  computed: {
    getLangConcat() {
      var lang = localStorage.getItem("lang");
      return lang.split("_").shift();
    },
    isLoggedIn() {
      return this.$store.getters["Auth/isLoggedIn"];
    },
    settings() {
      return this.$store.state.Config.setting;
    },
    authenticationType() {
      return this.$store.getters["Features/authenticationType"];
    },
  },
  mounted() {
    if (this.isLoggedIn == true) {
      this.$router.push("/");
    }
  },
  async created() {
    const lang = this.getLangConcat;
    const validateLanguage = await import("vee-validate/dist/locale/" + lang);

    this.$validator.localize(lang, {
      messages: validateLanguage.default.messages,
      attributes: {
        email: this.$t("email").toLowerCase(),
        firstname: this.$t("firstname").toLowerCase(),
        lastname: this.$t("lastname").toLowerCase(),
        terms: this.$t("privacyPolicy").toLowerCase(),
      },
    });
  },
  methods: {
    handleAuthentication() {
      this.message = "";
      this.submitted = true;
      if (this.authenticationType == "basic") {
        this.$validator.validate().then((isValid) => {
          if (isValid) {
            this.$store.dispatch("Auth/register", this.user).then(
              (data) => {
                this.message = data.message;
                this.successful = true;
              },
              (error) => {
                this.message = error;
                this.successful = false;
              }
            );
          }
        });
      } else if (this.authenticationType == "code") {
        this.$validator.validateAll().then((isValid) => {
          if (!isValid) {
            this.loading = false;
            return;
          }
          if (this.user.email && this.user.code) {
            this.$store.dispatch("Auth/login", this.user).then(
              (data) => {
                if (!data.succesful) this.message = data.error;
                this.$router.push("/");
              },
              (error) => {
                this.loading = false;
                this.message = error;
              }
            );
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);
.authentication {
  flex-grow: 1;
}
.submit-button-container {
  padding-top: 10px;
}
.auth-card {
  max-width: 25rem;
  margin: 0 auto;
  vertical-align: center;
}
.login-card-container {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 75vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}
.link-privacy {
  color: $color-secondary;
}
</style>
